import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import sortBy from "lodash/sortBy"

import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import useModal from "@components/modals/useModal"
import {
  dataConnectorsNames,
  DataConnectorSourceName,
  DataSourceName,
  fileSourcesNames,
  webSiteNames,
} from "@framework/types/upload"
import { getDataSourceNodeContextProps } from "@framework/constants/upload"
import DocumentIcon from "@components/ui/Icon/DocumentIcon"
import Text from "@components/ui/Typography/Text"
import { useController, useStore } from "@store"

import { ModalsTypes } from "../constants"

import styles from "./ChooseContentSourceModal.module.sass"

export interface ChooseContentSourceModalProps {
  doRefresh: () => void
}

const ALL_DATA_SOURCE_OPTIONS = sortBy(
  [...dataConnectorsNames, ...fileSourcesNames, ...webSiteNames].map(
    getDataSourceNodeContextProps
  ),
  (it) => it.label
)

const ChooseContentSourceModal: React.FC<ChooseContentSourceModalProps> =
  observer(({ doRefresh }) => {
    const { hideModal } = useModal(ModalsTypes.CHOOSE_CONTENT_SOURCE_MODAL)
    const DCAccountsModal = useModal(ModalsTypes.DATA_CONNECTOR_ACCOUNT_MODAL)
    const uploadModal = useModal(ModalsTypes.CONTENT_MANAGER_UPLOAD_FORM_MODAL)

    const { restrictionsStore: access } = useStore()

    const { contentManagerController } = useController()
    const { getContentSourceCountsByType, loadKibanaConnectorsCount } =
      contentManagerController

    const dataSourceOptions = React.useMemo(
      () =>
        ALL_DATA_SOURCE_OPTIONS.filter(
          (it) =>
            access.testDataSourceAccess(it.name) && it.name !== "sharepoint"
        ),
      [access.testDataSourceAccess]
    )

    const dataSourceClickHandler = (sourceName: DataSourceName | "unknown") => {
      if (sourceName) {
        const context = dataSourceOptions.find(
          (option) => option.name === sourceName
        )
        if (context) {
          const props = {
            label: context.label,
            sourceName: context.name as DataConnectorSourceName,
            icon: context.icon,
            doRefresh,
          }

          if (sourceName === "manual") {
            uploadModal.showModal(props)
          } else DCAccountsModal.showModal(props)
        }
      }
    }

    useEffect(() => {
      loadKibanaConnectorsCount()
    }, [])

    return (
      <BaseModal
        onClose={hideModal}
        className={styles.root}
        title={<ModalTitle titleText="Choose Content Source" />}
        containerClassName={styles.container}
      >
        <div className={styles.divider}>
          <div />
        </div>
        <div className={styles.grid}>
          {dataSourceOptions.map((context) => (
            <div
              className={`${styles.dataSourceCard}`}
              onClick={() => dataSourceClickHandler(context.name)}
              role="button"
              tabIndex={0}
              key={context.name}
              onKeyPress={() => {}}
            >
              <DocumentIcon icon={context.icon} />
              <Text variant="h5" align="center">
                {context.name === "manual" ? "Manual Upload" : context.label}
              </Text>
              <p className={styles.description}>
                {getContentSourceCountsByType(context.name)}
              </p>
            </div>
          ))}
        </div>
      </BaseModal>
    )
  })

export default ChooseContentSourceModal
