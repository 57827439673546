import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import { useFormikContext } from "formik"

import CheckboxWithLabel from "@components/ui/Checkbox/CheckboxWithLabel"
import Icon from "@components/ui/Icon/Icon"
import IconButton from "@components/ui/IconButton/IconButton"
import Text from "@components/ui/Typography/Text"
import { Company } from "@framework/types/company"
import { QueryFilterData } from "@framework/types/query-filter"
import { useStore } from "@store"

import { FormData } from "./FilterSubForm"

import styles from "./CompanySelection.module.sass"

export type CompanySelectionProps = {
  onCompanySelect: (company: Company) => void
}

const CompanySelection: React.FC<CompanySelectionProps> = observer(
  ({ onCompanySelect }) => {
    const formik = useFormikContext<FormData>()

    const activeFilters = formik.values.filters ?? []
    const { contentManagerStore } = useStore()
    const { companies } = contentManagerStore

    const checkAndApplyFilter = (filter: QueryFilterData) => {
      const newFilter = { ...filter, type: "company" }
      const found = activeFilters.find(
        (curFilter) => curFilter.id === newFilter.id
      )

      formik.setFieldValue(
        "filters",
        found
          ? activeFilters.filter((it) => it.id !== found.id)
          : [...activeFilters, newFilter]
      )
    }

    return (
      <div className={styles.root}>
        <Text variant="body2" className={styles.companyHeader}>
          Manufacturer
        </Text>
        <div className={styles.companiesList}>
          {companies.map((company) => (
            <div key={company.id} className={styles.checkboxWrapper}>
              <CheckboxWithLabel
                checked={
                  !!activeFilters.find(
                    (curFilter) => curFilter.id === company.id
                  )
                }
                onCheck={() => {
                  checkAndApplyFilter(company)
                }}
                className={clsx(styles.checkboxFilterName)}
                label={
                  <Text variant="body2" className={styles.name}>
                    {company.name}
                  </Text>
                }
              />
              <IconButton onClick={() => onCompanySelect(company)}>
                <Icon name="line-arrow-down" rotateAngle={-90} />
              </IconButton>
            </div>
          ))}
        </div>
      </div>
    )
  }
)

export default CompanySelection
