import React from "react"
import clsx from "clsx"
import sortBy from "lodash/sortBy"
import groupBy from "lodash/groupBy"
import { observer, useLocalObservable } from "mobx-react-lite"

import { useStore } from "@store/index"
import MainLayout from "@components/layout/MainLayout/MainLayout"
import DocumentIcon from "@components/ui/Icon/DocumentIcon"
import LinkCard, { LinkCardProps } from "@components/ui/LinkCard/LinkCard"
import {
  DataSourceNodeContextProps,
  DATA_SOURCES_FAVORITES_KEY,
  getDataSourceNodeContextProps,
} from "@framework/constants/upload"
import {
  fileSourcesNames,
  dataConnectorsNames,
  webSiteNames,
  DataSourceStatusReport,
  DataSourceName,
  isDataConnector,
} from "@framework/types/upload"
import mainRoutes from "@root/main.routes"
import EntityPageHeaderLayout from "@components/layout/EntityPageHeaderLayout/EntityPageHeaderLayout"
import Text from "@components/ui/Typography/Text"
import Icon from "@components/ui/Icon/Icon"
import LocalFavoriteList from "@store/utils/LocalFavoriteList"
import NotFound from "@components/ui/NotFound/NotFound"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"
import useAllUsers from "@store/user/useAllUsers"
import { countSuffix } from "@utils/numberUtils"
import { capitalizeFirstLetter } from "@utils/textUtils"

import styles from "./DataSourcesRoot.module.sass"

const dataSourceOptions = sortBy(
  [...dataConnectorsNames, ...fileSourcesNames, ...webSiteNames].map(
    getDataSourceNodeContextProps
  ),
  (it) => it.label
)

export interface DataSourcesRootProps {}

const DataSourcesRootPage: React.FC<DataSourcesRootProps> = observer(() => {
  const { restrictionsStore: access } = useStore()

  useAllUsers()

  const selectDCModal = useModal(
    ModalsTypes.SELECT_DATA_CONNECTOR_ACCOUNT_MODAL
  )

  const favoritesList = useLocalObservable(
    () => new LocalFavoriteList({ localStoreKey: DATA_SOURCES_FAVORITES_KEY })
  )

  const validOptions = React.useMemo(
    () =>
      dataSourceOptions.filter(
        (it) =>
          access.testDataSourceAccess(it.name) &&
          it.name !== "sharepoint_online"
      ),
    [dataSourceOptions, access.testDataSourceAccess]
  )

  const { favoriteOptions = [], otherOptions = [] } = React.useMemo(
    () =>
      groupBy(validOptions, (it) => {
        return favoritesList.isFavorite(it.name)
          ? "favoriteOptions"
          : "otherOptions"
      }),
    [validOptions, favoritesList.isFavorite]
  )

  const isSegmented =
    favoritesList.favorites.length > 0 &&
    favoritesList.favorites.length !== validOptions.length

  const dataSourceClickHandler =
    (sourceName: DataSourceName | "unknown") => (e: React.MouseEvent) => {
      if (!isDataConnector(sourceName)) return

      e.preventDefault()

      selectDCModal.showModal({ sourceName, autoSelect: true })
    }

  return (
    <MainLayout>
      <div className={styles.root}>
        <EntityPageHeaderLayout
          left={<Text variant="h1">Content Manager</Text>}
        />

        {validOptions.length > 0 ? (
          <div className={styles.body}>
            {favoriteOptions.length > 0 && (
              <section>
                {isSegmented && (
                  <Text variant="caption1">
                    {favoriteOptions.length} Favorite
                  </Text>
                )}

                <div className={styles.grid}>
                  {favoriteOptions.map((context) => {
                    const isFavorite = favoritesList.isFavorite(context.name)
                    return (
                      <DataSourceCard
                        data={context}
                        isFavorite={isFavorite}
                        onClick={dataSourceClickHandler(context.name)}
                        onFavoriteToggleClick={() =>
                          favoritesList.toggleFavorite(context.name)
                        }
                        key={context.name}
                      />
                    )
                  })}
                </div>
              </section>
            )}

            <section>
              {isSegmented && (
                <Text variant="caption1">{otherOptions.length} Other</Text>
              )}

              <div className={styles.grid}>
                {otherOptions.map((context) => {
                  const isFavorite = favoritesList.isFavorite(context.name)
                  return (
                    <DataSourceCard
                      data={context}
                      isFavorite={isFavorite}
                      onClick={dataSourceClickHandler(context.name)}
                      onFavoriteToggleClick={() =>
                        favoritesList.toggleFavorite(context.name)
                      }
                      key={context.name}
                    />
                  )
                })}
              </div>
            </section>
          </div>
        ) : (
          <NotFound>There are no data sources allowed for you</NotFound>
        )}
      </div>
    </MainLayout>
  )
})

export default DataSourcesRootPage

interface DataSourceCardProps {
  isFavorite?: boolean
  onClick?: LinkCardProps["onClick"]
  onFavoriteToggleClick?: (options: DataSourceNodeContextProps) => void
  data: DataSourceNodeContextProps
}

const DataSourceCard: React.FC<DataSourceCardProps> = observer(
  ({ data, isFavorite, onClick, onFavoriteToggleClick }) => {
    const {
      uploadStore: { dataSourceDetails },
    } = useStore()

    const key = data.name

    const handleFavoriteToggle = (e: React.MouseEvent) => {
      e.preventDefault()
      e.stopPropagation()

      onFavoriteToggleClick?.(data)
    }

    const details = dataSourceDetails.dataMap.get(data.name)

    return (
      <LinkCard
        className={styles.card}
        disabled={data.name === "unknown"}
        onClick={onClick}
        leftControl={
          <button
            className={clsx("hiddenTrigger", { active: isFavorite })}
            type="button"
            onClick={handleFavoriteToggle}
          >
            <Icon
              name={
                isFavorite ? "pushpin-vertical-fill" : "pushpin-vertical-line"
              }
            />
          </button>
        }
        description={renderDescription(details)}
        to={mainRoutes.upload([key])}
        label={data.label}
        icon={<DocumentIcon icon={data.icon} />}
        key={key}
      />
    )
  }
)

const renderDescription = (data?: DataSourceStatusReport) => {
  if (data == null) return null

  const connectorContext = getDataSourceNodeContextProps(data.name)
  const foldersContext = getDataSourceNodeContextProps(
    connectorContext.contentItemName ?? "folders"
  )
  const filesContext = getDataSourceNodeContextProps(
    foldersContext.contentItemName ?? "file"
  )

  if (data.sourceType === "data-connector") {
    const totalAccounts = Number(data.totalAccounts ?? 0)
    const totalFolders = Number(data.totalFolders ?? 0)
    const totalFiles = Number(data.totalFiles ?? 0)

    return [
      `${totalAccounts} ${capitalizeFirstLetter(
        connectorContext.itemName
      )}${countSuffix(totalAccounts)}`,

      `${totalFolders} ${capitalizeFirstLetter(
        foldersContext ? foldersContext.itemName : "Folder"
      )}${countSuffix(totalFolders)}`,

      `${totalFiles} ${capitalizeFirstLetter(
        filesContext ? filesContext.itemName : "File"
      )}${countSuffix(totalFiles)}`,
    ].join(" | ")
  }

  const totalFiles = Number(data?.count ?? 0)

  return `${totalFiles} ${capitalizeFirstLetter(
    filesContext ? filesContext.itemName : "File"
  )}${countSuffix(totalFiles)}`
}
