import { makeAutoObservable } from "mobx"
import uniq from "lodash/uniq"

import productsService from "@services/products.service"
import { CompanyTheme } from "@framework/types/company"
import { FailResponse, SuccessResponse } from "@framework/types/common"
import solutionsService from "@services/solutions.service"
import { errorToText } from "@store/utils/error-handlers"
import { ReportData } from "@framework/types/workbook-report"

import UnifiedMatrixStore from "./unified-matrix.store"

export default class UnifiedMatrixController {
  matrix: UnifiedMatrixStore

  constructor(injections: { unifiedMatrixStore: UnifiedMatrixStore }) {
    this.matrix = injections.unifiedMatrixStore

    makeAutoObservable(this)
  }

  filesAbortController: AbortController | null = null

  fetchFiles = async (query: string): Promise<string[]> => {
    const store = this.matrix.filesStore

    try {
      if (!this.filesAbortController?.signal.aborted)
        this.filesAbortController?.abort()

      this.filesAbortController = new AbortController()

      store.isLoading = true

      const response = await productsService.getTargetFiles(
        query,
        this.filesAbortController.signal
      )

      store.setData(response.data.data)

      return uniq(response.data.data.map((it) => it.name))
    } catch (error) {
      store.error = "Unexpected error"
    } finally {
      store.isLoading = false
    }
    return []
  }

  fetchProducts = async (query: string): Promise<string[]> => {
    const store = this.matrix.productsStore

    try {
      if (!this.filesAbortController?.signal.aborted)
        this.filesAbortController?.abort()

      this.filesAbortController = new AbortController()

      store.isLoading = true

      const response = await solutionsService.getFilters(
        {
          filters: [
            {
              type: "product",
              search: query,
              pageSize: 100,
            },
          ],
        },
        undefined,
        this.filesAbortController.signal
      )

      const items = response.data?.product?.products?.items ?? []

      store.setData(items)

      return items.map((it) => it.name)
    } catch (error) {
      store.error = "Unexpected error"
    } finally {
      store.isLoading = false
    }
    return []
  }

  generateThemes = async (
    companyName: string,
    category: string,
    businessUnit?: string
  ): Promise<SuccessResponse<string> | FailResponse> => {
    try {
      const response = await productsService.generateThemes({
        companyName,
        category,
        businessUnit,
      })

      const themes = response.data.data

      return {
        status: "SUCCESS",
        data: themes.length ? makeThemesReport(themes) : "-",
      }
    } catch (error) {
      return {
        status: "FAILED",
        message: errorToText(error),
      }
    }
  }

  generateAccountPlan = async (
    targetCompanyUrl: string,
    myCompanyUrl: string,
    targetCompanyBu?: string,
    additionalContext?: string
  ): Promise<SuccessResponse<{ id: string; url: string }> | FailResponse> => {
    try {
      const response = await productsService.generateAccountPlan({
        targetCompanyUrl,
        myCompanyUrl,
        targetCompanyBu,
        additionalContext,
      })

      const reportId = response.data.split("/").at(-1)

      if (!reportId) throw new Error("Failed to extract report ID")

      return {
        status: "SUCCESS",
        data: { id: reportId, url: response.data },
      }
    } catch (error) {
      return {
        status: "FAILED",
        message: errorToText(error),
      }
    }
  }

  generateDocumentComplianceReport = async (
    authorityDocument: string,
    regulatedDocument: string
  ): Promise<SuccessResponse<{ id: string; url: string }> | FailResponse> => {
    try {
      const response = await productsService.generateDocumentComplianceReport({
        authorityDocument,
        regulatedDocument,
      })

      const reportId = response.data.split("/").at(-1)

      if (!reportId) throw new Error("Failed to extract report ID")

      return {
        status: "SUCCESS",
        data: { id: reportId, url: response.data },
      }
    } catch (error) {
      return {
        status: "FAILED",
        message: errorToText(error),
      }
    }
  }

  generateProductPositioningReport = async (
    application: string,
    targetProduct: string,
    alternateProducts: string[]
  ): Promise<SuccessResponse<{ id: string; url: string }> | FailResponse> => {
    try {
      const response = await productsService.generateProductPositioningReport({
        application,
        targetProduct,
        alternateProducts,
      })

      const reportId = response.data.split("/").at(-1)

      if (!reportId) throw new Error("Failed to extract report ID")

      return {
        status: "SUCCESS",
        data: { id: reportId, url: response.data },
      }
    } catch (error) {
      return {
        status: "FAILED",
        message: errorToText(error),
      }
    }
  }

  resetWorkbookReport = () => {
    const store = this.matrix.reportStore
    store.setReport(null, null)
  }

  getWorkbookReport = async (
    reportId: string
  ): Promise<SuccessResponse<ReportData> | FailResponse> => {
    const store = this.matrix.reportStore

    try {
      store.isLoading = true

      const response = await productsService.getWorkbookReport(reportId)

      const { data } = response

      store.setReport(reportId, data)

      return {
        status: "SUCCESS",
        data,
      }
    } catch (error) {
      return {
        status: "FAILED",
        message: "Unexpected error",
      }
    } finally {
      store.isLoading = false
    }
  }

  downloadReport = async (
    reportId: string
  ): Promise<SuccessResponse<any> | FailResponse> => {
    const store = this.matrix.reportStore

    try {
      store.isLoading = true

      // TODO

      return {
        status: "SUCCESS",
        data: "",
      }
    } catch (error) {
      return {
        status: "FAILED",
        message: "Unexpected error",
      }
    } finally {
      store.isLoading = false
    }
  }
}

const makeThemesReport = (themes: CompanyTheme[]) => {
  return themes.map((it) => `- ${it.content}`).join("\n")
}
