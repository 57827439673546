import React from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"

import TooltipContainer from "@components/ui/Tooltip/TooltipContainer"
import Tooltip from "@components/ui/Tooltip/Tooltip"
import useToggle from "@components/hooks/useToggle"
import Icon from "@components/ui/Icon/Icon"
import MenuItem from "@components/containers/Sidebar/MenuItem"
import { ContextOption } from "@framework/types/common"
import IconButton from "@components/ui/IconButton/IconButton"
import { KibanaConnector } from "@framework/types/content-manager"
import { useController } from "@store"

import useModal from "../useModal"
import { ModalsTypes } from "../constants"

type ContextMenuProps = {
  connector: KibanaConnector
  isWebsite: boolean
}

const ContextMenu: React.FC<ContextMenuProps> = observer(
  ({ connector, isWebsite }) => {
    const { isOpened, handleToggle, setOpened } = useToggle()
    const confirmModal = useModal(ModalsTypes.CONFIRM_MODAL)
    const { contentManagerController } = useController()
    const alert = useAlert()

    const contextOptions: ContextOption<string>[] = [
      {
        label: "Remove",
        icon: "trash-can",
        color: "red",
        type: "Remove",
      },
    ]

    const deleteAccount = async () => {
      const success =
        await contentManagerController.deleteKibanaConnectorAccount(
          connector.id
        )
      const heading = isWebsite ? "Website" : "Account"
      if (success) {
        alert.success(`Successfully removed ${heading}`)
      } else {
        alert.error(`Failed to remove ${heading}`)
      }
    }

    const showDeleteConfirmModal = () => {
      confirmModal.showModal({
        message: (
          <>
            Delete {connector.name}!
            <br />
            Are you sure you want to proceed?
          </>
        ),
        confirm: "positive",
        onConfirm: deleteAccount,
        confirmButtonText: "Proceed",
      })
    }

    return (
      <Tooltip
        show={isOpened}
        onClose={() => setOpened(false)}
        mode="onFocus"
        content={
          <TooltipContainer color="primary" placement="left">
            {contextOptions.map(({ label, type, ...rest }) => (
              <MenuItem key={type} onClick={showDeleteConfirmModal} {...rest}>
                {label}
              </MenuItem>
            ))}
          </TooltipContainer>
        }
      >
        <IconButton size="medium" active={isOpened} onClick={handleToggle}>
          <Icon name="dots" />
        </IconButton>
      </Tooltip>
    )
  }
)

export default ContextMenu
