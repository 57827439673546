import React, { useState, useEffect, useCallback } from "react"

import Icon from "@components/ui/Icon/Icon"
import HTMLText from "@components/ui/Typography/HTMLText"
import TextResult from "@pages/launch-solutions/SolutionTabsContainer/components/Tabs/FactFinder/components/SourceView/components/TextResult/TextResult"
import Text from "@components/ui/Typography/Text"
import { useController } from "@store"
import Loader from "@components/ui/Loader/BarLoader"

import type { ImageDetails } from "../AnswerOriginalView"
import ExpertAnswer from "./ExpertAnswer"

import styles from "./AnswerViewer.module.sass"

const DEFAULT_SCALE = 90

const AnswerViewer: React.FC<{
  image: ImageDetails
  fullscreen: boolean
  onFullScreenToggle: (show: boolean) => void
}> = React.memo(({ image, fullscreen, onFullScreenToggle }) => {
  const [scale, setScale] = useState<number>(DEFAULT_SCALE)
  const [pdfState, setPdfState] = useState<{
    isPdf: boolean
    pdfUrl: string
    loadingPdf: boolean
  }>({
    isPdf: false,
    pdfUrl: "",
    loadingPdf: false,
  })
  const { dataConnectorController } = useController()

  const handleScaleUp = useCallback(() => {
    setScale((currScale) => (currScale < 170 ? currScale + 10 : currScale))
  }, [])

  const handleScaleDown = useCallback(() => {
    setScale((currScale) => (currScale > 20 ? currScale - 10 : currScale))
  }, [])

  const getPdfUrl = useCallback(async () => {
    setPdfState((prevState) => ({ ...prevState, loadingPdf: true }))
    const res = await dataConnectorController.getSignedDataURL(
      image?.details?.source || ""
    )
    setPdfState({
      isPdf: true,
      pdfUrl: `${(res as any)?.data}&#page=${image?.details?.page}`,
      loadingPdf: false,
    })
  }, [dataConnectorController, image?.details?.source, image?.details?.page])

  useEffect(() => {
    setScale(DEFAULT_SCALE)
    if (
      image?.details?.source_type === "manual" &&
      image?.details?.source &&
      image?.details?.source?.endsWith(".pdf")
    ) {
      setPdfState((prevState) => ({ ...prevState, isPdf: true, pdfUrl: "" }))
      getPdfUrl()
    } else {
      setPdfState({ isPdf: false, pdfUrl: "", loadingPdf: false })
    }
  }, [image, getPdfUrl])

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        {pdfState.isPdf ? (
          <div className={styles.pdfContainer}>
            {pdfState.loadingPdf ? (
              <Loader size="medium" />
            ) : (
              <iframe title={image?.details?.source} src={pdfState.pdfUrl} />
            )}
          </div>
        ) : image?.url ? (
          <div className={styles.imageContainer}>
            <img
              style={{
                width: fullscreen ? `${scale}%` : "100%",
              }}
              src={image?.url}
              alt={image?.url}
            />
            <div className={styles.control}>
              <Icon
                onClick={() => onFullScreenToggle(!fullscreen)}
                className={styles.controlButton}
                name={fullscreen ? "minimize" : "maximize"}
              />
              {fullscreen && (
                <>
                  <Icon
                    onClick={handleScaleUp}
                    className={styles.controlButton}
                    name="zoom-in"
                  />
                  <Icon
                    onClick={handleScaleDown}
                    className={styles.controlButton}
                    name="zoom-out"
                  />
                </>
              )}
            </div>
          </div>
        ) : (
          <>
            {image?.isExpertAnswer ? (
              <ExpertAnswer details={image?.details} />
            ) : (
              <Text className={styles.extractedTextHeading} variant="body1">
                Extracted Text
              </Text>
            )}

            {image?.isExpertAnswer ? (
              <HTMLText className={styles.textContent}>
                {image?.description}
              </HTMLText>
            ) : (
              <TextResult
                className={styles.textContent}
                content={image?.description}
              />
            )}
          </>
        )}
      </div>
    </div>
  )
})

export default AnswerViewer
