import React, { useState } from "react"
import { useAlert } from "react-alert"
import sortBy from "lodash/sortBy"
import { observer } from "mobx-react-lite"

import Button from "@components/ui/Button/Button"
import { useStore } from "@store"
import { useUnstructuredSearchEntity } from "@pages/search/SearchContext/SearchResultContext"
import { SummaryQueryType } from "@store/search/types"
import SearchSummaryBlockStore from "@store/search/search-summary-block.store"
import { AnswerStatus } from "@framework/types/question"
import Icon from "@components/ui/Icon/Icon"
import Text from "@components/ui/Typography/Text"

import styles from "./VerifyAnswer.module.sass"

type VerifyButtonProps = {
  className?: string
  disabled?: boolean
  onVerify?: () => Promise<void>
}

const SUCCESS_MESSAGE = "Answer verified successfully!"
const ERROR_MESSAGE = "There was an error verifying the answer."

const VerifyButton: React.FC<VerifyButtonProps> = observer(
  ({ className = "", disabled = false }) => {
    const [loading, setLoading] = useState(false)

    const {
      knowledgeStore: { assignExpertQuestionToSelf, updateExpertQuestion },
      userStore: { user },
    } = useStore()

    const { searchEntity } = useUnstructuredSearchEntity()

    const { isVerifiedSummary, setVerifiedSummary } = searchEntity

    const rawAnswer = searchEntity.allRawData

    const alert = useAlert()

    const handleVerify = async () => {
      if (disabled || loading || !user?.id) return

      if (!rawAnswer) return

      const summaries: string[] = []

      setLoading(true)
      try {
        sortBy(searchEntity.blocks, (block) => {
          if (block instanceof SearchSummaryBlockStore) {
            if (block.queryType === "PRODUCT_FINDER") return 1
            if (block.queryType === "TABLE") return 1
            if (block.queryType === "BASIC") return 2
            if (block.queryType === "ADVANCED") return 3
          }
          return 0
        }).forEach((block) => {
          if (block instanceof SearchSummaryBlockStore) {
            const title = getSummaryTitle(block.queryType)
            summaries.push(
              `<h3>${title}</h3><p>${block.searchSummary.summary}</p><br/>`
            )
          }
        })

        const summary = summaries.join("")

        const data = await assignExpertQuestionToSelf(
          searchEntity.filter.searchAvatar,
          searchEntity.filter.searchAvatarId,
          searchEntity.filter.searchQuery,
          [user.id],
          rawAnswer,
          "Verify this answer",
          summary,
          true
        )

        if (!data?.status) {
          alert.error("There was an error verifying the answer.")
          return
        }

        const questions = data.data as { id: string }[]

        const updatedQuestion = await updateExpertQuestion(
          questions[0].id,
          AnswerStatus.ANSWERED,
          summary
        )

        if (!updatedQuestion) {
          alert.error(ERROR_MESSAGE)
          return
        }

        setVerifiedSummary(true)

        alert.success(SUCCESS_MESSAGE)
      } catch (error) {
        alert.error(ERROR_MESSAGE)
      } finally {
        setLoading(false)
      }
    }

    return isVerifiedSummary ? (
      <span className={styles.verifyBadge}>
        <Icon color="green" name="shield-check-fill" />{" "}
        <Text className={styles.buttons}>Verified by you</Text>
      </span>
    ) : (
      <Button
        onClick={handleVerify}
        before={<Icon name="shield-check" />}
        size="small"
        disabled={loading}
        variant="outlined"
        color="green"
        className={styles.buttons}
      >
        Verify this answer
      </Button>
    )
  }
)

export default VerifyButton

const getSummaryTitle = (queryType: SummaryQueryType) => {
  switch (queryType) {
    case "ADVANCED":
      return "Revised Summary"
    case "TABLE":
      return "Table Summary"
    case "PRODUCT_FINDER":
      return "Product Recomendation"
    default:
      return "Summary"
  }
}
