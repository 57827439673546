import React, { useEffect, useRef, useState } from "react"

import Button from "@components/ui/Button/Button"
import Text from "@components/ui/Typography/Text"
import { TextAreaInput } from "@components/ui/TextInput/TextInput"
import Icon from "@components/ui/Icon/Icon"

import styles from "./MarkdownEditor.module.sass"

interface MarkdownEditorProps {
  onSave: (content: string) => Promise<void>
  onClose: () => void
  value: string
}

const MarkdownEditor: React.FC<MarkdownEditorProps> = ({
  onSave,
  onClose,
  value,
}) => {
  const [content, setContent] = useState(value)
  const [isSaving, setIsSaving] = useState(false)

  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  const handleSave = async () => {
    setIsSaving(true)
    try {
      await onSave(content)
    } finally {
      setIsSaving(false)
    }
  }

  const adjustTextAreaHeight = (target: HTMLTextAreaElement) => {
    const textArea = target
    textArea.style.height = "auto"
    textArea.style.height = `${textArea.scrollHeight}px`
  }

  useEffect(() => {
    if (textAreaRef.current) {
      adjustTextAreaHeight(textAreaRef.current)
    }
  }, [textAreaRef])

  return (
    <div>
      <TextAreaInput
        value={content}
        onChange={(e) => {
          const textArea = e.target as HTMLTextAreaElement
          setContent(textArea.value)
          adjustTextAreaHeight(textArea)
        }}
        ref={textAreaRef}
        disabled={isSaving}
      />
      <div className={styles.footer}>
        <div className={styles.footerText}>
          <Icon name="info" className={styles.infoIcon} />{" "}
          <Text variant="body1" color="text50Color" className={styles.info}>
            Once saved, this edited summary will be finalized as Experts
            Verified Citations for reference.
          </Text>
        </div>

        <div className={styles.buttonContainer}>
          <Button
            onClick={onClose}
            variant="outlined"
            className={styles.closeButton}
            size="small"
          >
            Cancel
          </Button>
          <Button
            size="small"
            onClick={handleSave}
            variant="contained"
            color="primary"
            disabled={isSaving}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  )
}

export default MarkdownEditor
