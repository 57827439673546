import { makeAutoObservable } from "mobx"
import { nanoid } from "nanoid"

import {
  AnswerStatus,
  ExpertQuestion,
  ExpertQuestionDTO,
  ExpertQuestionThreadDTO,
  ExpertQuestionsData,
} from "@framework/types/question"
import { BaseUserData, SimpleUserData } from "@framework/types/user"
import knowledgeService from "@services/knowledge.service"
import { AnswerResponseData } from "@services/search.service"

export class KnowledgeStore {
  constructor() {
    makeAutoObservable(this)
  }

  isUpdateExpertQuestionLoading: boolean = false

  updateExpertQuestionError: string | null = null

  isExpertQuestionsLoading: boolean = false

  expertQuestionsError: string | null = null

  expertQuestions: Partial<ExpertQuestionsData> = {}

  lastAssignedQuestionId = ""

  loadExpertQuestions = async () => {
    try {
      this.isExpertQuestionsLoading = true
      this.expertQuestionsError = null
      this.expertQuestions = {}

      const response = await knowledgeService.getExpertQuestions()

      this.expertQuestions = {
        waiting:
          response.data.data.waiting?.map(
            (expertQuestionDTO: ExpertQuestionDTO): ExpertQuestion => ({
              ...expertQuestionDTO,
              thread: {
                [nanoid()]: {
                  author: expertQuestionDTO.assignee,
                  answer: null,
                },
              },
            })
          ) ?? [],
        answered:
          response.data.data.answered?.map(
            (expertQuestionDTO: ExpertQuestionDTO): ExpertQuestion => ({
              ...expertQuestionDTO,
              thread: {
                [nanoid()]: {
                  author: expertQuestionDTO.assignee,
                  answer: expertQuestionDTO.answer,
                },
              },
            })
          ) ?? [],
        declined:
          response.data.data.declined?.map(
            (expertQuestionDTO: ExpertQuestionDTO): ExpertQuestion => ({
              ...expertQuestionDTO,
              thread: {
                [nanoid()]: {
                  author: expertQuestionDTO.assignee,
                  answer: null,
                },
              },
            })
          ) ?? [],
      }
    } catch (error) {
      this.expertQuestionsError = "failed to load expert questions"
    } finally {
      this.isExpertQuestionsLoading = false
    }
    return this.expertQuestionsError
  }

  loadThreadByQuestion = async (questionText: string, questionId: string) => {
    try {
      this.isUpdateExpertQuestionLoading = true

      const response = await knowledgeService.getExpertQuestionThread(
        questionText,
        questionId
      )

      const {
        data: { data: thread },
      } = response

      const normalizedThread = thread.reduce(
        (acc, expertQuestionThreadDTO: ExpertQuestionThreadDTO) => ({
          ...acc,
          [nanoid()]: {
            author: expertQuestionThreadDTO.author,
            answer: expertQuestionThreadDTO.answer,
            lastUpdated: expertQuestionThreadDTO.lastUpdated,
          },
        }),
        {} as {
          [key: string]: {
            author: SimpleUserData
            answer: string | null
            lastUpdated?: string
          }
        }
      )

      this.expertQuestions = {
        waiting:
          this.expertQuestions.waiting?.map(
            (expertQuestion: ExpertQuestion): ExpertQuestion => ({
              ...expertQuestion,
              thread: {
                ...(questionText === expertQuestion.question
                  ? normalizedThread
                  : {}),
              },
            })
          ) ?? [],
        answered:
          this.expertQuestions.answered?.map(
            (expertQuestion: ExpertQuestion): ExpertQuestion => ({
              ...expertQuestion,
              thread: {
                ...(questionText === expertQuestion.question
                  ? normalizedThread
                  : {}),
              },
            })
          ) ?? [],
        declined:
          this.expertQuestions.declined?.map(
            (expertQuestion: ExpertQuestion): ExpertQuestion => ({
              ...expertQuestion,
              thread: {
                ...(questionText === expertQuestion.question
                  ? normalizedThread
                  : {}),
              },
            })
          ) ?? [],
      }
    } catch (error) {
      this.expertQuestionsError = "Failed to load thread for this question"
    } finally {
      this.isUpdateExpertQuestionLoading = false
    }
  }

  loadExpertsAssignedToQuestion = async (
    questionId: string,
    questionText: string
  ) => {
    try {
      const response = await knowledgeService.getExpertAssignedToQuestion(
        questionText,
        questionId
      )

      return response.data.data ?? []
    } catch (error) {
      //
    }
    return []
  }

  updateExpertQuestion = async (
    questionId: string,
    status: AnswerStatus,
    answer: string | null = null
  ) => {
    this.isUpdateExpertQuestionLoading = true
    this.updateExpertQuestionError = null
    try {
      await knowledgeService.updateExpertQuestion(
        questionId,
        status,
        answer ?? undefined
      )
      return true
    } catch (error) {
      this.updateExpertQuestionError = "Can't update expert Question"
    } finally {
      this.isUpdateExpertQuestionLoading = false
    }
    return false
  }

  expertsList: BaseUserData[] = []

  isExpertsLoading = true

  loadExpertsError = ""

  loadExperts = async (avatarId: string) => {
    this.isExpertsLoading = true
    this.loadExpertsError = ""
    try {
      const response = await knowledgeService.getKnowledgeExperts(avatarId)
      this.expertsList = response.data.data.experts ?? []
      return true
    } catch (error) {
      this.loadExpertsError = `Can't load experts`
    } finally {
      this.isExpertsLoading = false
    }
    return false
  }

  reassignQuestionToExpert = async (
    questionId: string,
    expertIds: string[]
  ) => {
    try {
      this.isUpdateExpertQuestionLoading = true
      this.updateExpertQuestionError = null

      await knowledgeService.reassignQuestionToExpert(questionId, expertIds)
    } catch (error) {
      this.updateExpertQuestionError = `Failed to assign question to experts`
    } finally {
      this.isUpdateExpertQuestionLoading = false
    }
    return this.updateExpertQuestionError
  }

  cancelLastAssignation = async (questionId: string) => {
    this.isUpdateExpertQuestionLoading = true
    this.updateExpertQuestionError = null
    try {
      await knowledgeService.cancelLastQuestionAssignation(questionId)
      this.lastAssignedQuestionId = questionId
      return true
    } catch (error) {
      this.updateExpertQuestionError = `Cancelation failed`
    } finally {
      this.isUpdateExpertQuestionLoading = false
    }
    return false
  }

  assignExpertQuestionToSelf = async (
    avatarName: string,
    avatarId: string,
    question: string,
    delegatedToUserIds: string[],
    answer?: AnswerResponseData | AnswerResponseData[],
    questionNote?: string,
    summary?: string,
    suppressNotification: boolean = false
  ) => {
    try {
      const response =
        await knowledgeService.assignationQuestionDirectlyMultipleExperts({
          question,
          answer: JSON.stringify(answer),
          avatar: avatarName,
          avatarId,
          delegatedToUserIds,
          userNote: questionNote,
          summary,
          suppressNotification,
        })

      return response.data
    } catch (error) {
      return null
    }
  }

  assignQuestionToExpertDirectly = async (
    avatarName: string,
    avatarId: string,
    question: string,
    delegatedToUserIds: string[],
    answer?: AnswerResponseData | AnswerResponseData[],
    questionNote?: string,
    summary?: string
  ) => {
    this.isUpdateExpertQuestionLoading = true
    this.updateExpertQuestionError = null

    try {
      await knowledgeService.assignationQuestionDirectlyMultipleExperts({
        question,
        answer: JSON.stringify(answer),
        avatar: avatarName,
        avatarId,
        delegatedToUserIds,
        userNote: questionNote,
        summary,
      })
    } catch (error) {
      this.updateExpertQuestionError = `Cancelation failed`
    } finally {
      this.isUpdateExpertQuestionLoading = false
    }
    return this.updateExpertQuestionError
  }
}

export default KnowledgeStore
