import React, { useState } from "react"
import clsx from "clsx"

import { Option } from "@framework/types/utils"

import Icon from "../Icon/Icon"

import styles from "./Select.module.sass"

interface SelectProps {
  options: Option[]
  onSelect: (option: Option) => void
  placeholder?: string
  className?: string
  error?: boolean
}

const Select: React.FC<SelectProps> = ({
  options,
  onSelect,
  placeholder = "Select an option...",
  className,
  error,
}) => {
  const [selectedOption, setSelectedOption] = useState<Option | null>(null)
  const [isOpen, setIsOpen] = useState(false)

  const handleSelect = (option: Option) => {
    setSelectedOption(option)
    onSelect(option)
    setIsOpen(false)
  }

  return (
    <div className={clsx(styles.selectContainer, className)}>
      <div
        className={clsx(styles.selectBox, {
          [styles.selectBoxOpen]: isOpen,
          [styles.selectBoxError]: error,
        })}
        onClick={() => setIsOpen((prev) => !prev)}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            setIsOpen((prev) => !prev)
          }
        }}
        tabIndex={0}
        role="button"
      >
        {selectedOption ? selectedOption.value : placeholder}
        <Icon name="arrow-down" rotateAngle={isOpen ? 180 : 0} />
      </div>
      {isOpen && (
        <div className={styles.optionsList}>
          {options.map((option) => (
            <button
              key={option.name}
              className={styles.optionItem}
              onClick={() => handleSelect(option)}
              type="button"
            >
              {option.value}
            </button>
          ))}
        </div>
      )}
    </div>
  )
}

export default Select
