import React, { useState } from "react"

import Icon from "@components/ui/Icon/Icon"
import Text from "@components/ui/Typography/Text"
import IconButton from "@components/ui/IconButton/IconButton"

import styles from "./SearchInfoBanner.module.sass"

const SearchInfoBanner = () => {
  const [show, setShow] = useState(true)
  return show ? (
    <div className={styles.root}>
      <div className={styles.headings}>
        <Text variant="h4">
          <Icon name="search" />
          Results are limited to 10,000 documents
        </Text>
        <Text variant="body2">
          Only the first 10,000 results are accessible, Please use the search
          bar to filter down your results.
        </Text>
      </div>
      <IconButton onClick={() => setShow(false)}>
        <Icon name="cross" />
      </IconButton>
    </div>
  ) : null
}

export default SearchInfoBanner
