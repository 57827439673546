import { AxiosResponse } from "axios"

import { SolutionEntity } from "@framework/types/solution"
import { GetFilterRequest, QueryFilter } from "@framework/types/query-filter"

import { AvatarDetailsData } from "../framework/types/avatar"
import { DefaultSuccessResponse } from "./common/types"
import HttpService from "./http.service"

export interface GetSolutionsResponse extends DefaultSuccessResponse {
  data: AvatarDetailsData
}

export interface GetProductSolutionListResponse {
  data: SolutionEntity[]
}

export interface GetProductSolutionResponse {
  data: SolutionEntity
}

export interface GetFiltersResponse extends DefaultSuccessResponse {
  data: QueryFilter
}

class SolutionAPI extends HttpService {
  getSolutions = (
    channelId: string
  ): Promise<AxiosResponse<GetSolutionsResponse>> =>
    this.get("ts/channel", true, { channelId })

  getAllProductSolutions = (): Promise<
    AxiosResponse<GetProductSolutionListResponse>
  > => this.get("ts/admin/product-solution/all")

  getFilters = (
    body: GetFilterRequest,
    companyId?: string,
    signal?: AbortSignal
  ): Promise<AxiosResponse<QueryFilter>> =>
    this.post(
      `ts/hub/filters${companyId ? `?companyId=${companyId}` : ""}`,
      body,
      true,
      { signal }
    )

  createProductSolution = (
    data: any
  ): Promise<AxiosResponse<GetProductSolutionResponse>> =>
    this.post("ts/admin/product-solution", data)

  updateProductSolution = (
    id: string,
    data: any
  ): Promise<AxiosResponse<GetProductSolutionResponse>> =>
    this.patch(`ts/admin/product-solution/${id}`, data)

  removeProductSolution = (
    productSolutionId: string
  ): Promise<AxiosResponse<DefaultSuccessResponse>> =>
    this.delete(`ts/admin/product-solution`, null, true, { productSolutionId })
}

export default new SolutionAPI()
