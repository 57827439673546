import React, { useState } from "react"

import Button from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"
import Text from "@components/ui/Typography/Text"
import { SyncJobInfo } from "@framework/types/content-manager"

import styles from "./SyncInfo.module.sass"

type SyncInfoProps = {
  jobs: SyncJobInfo[]
}

const SyncInfo: React.FC<SyncInfoProps> = ({ jobs }) => {
  const [hideJobs, setHideJobs] = useState<Record<string, boolean>>({})

  const handleHideJob = (jobId: string) => {
    setHideJobs((prevHideJobs) => ({
      ...prevHideJobs,
      [jobId]: true,
    }))
  }

  return (
    <>
      {jobs.map(
        (currentJob) =>
          !hideJobs[currentJob?.id] && (
            <div className={styles.root} key={currentJob?.id}>
              <div>
                <div className={styles.info}>
                  <div className={styles.iconWrapper}>
                    <Icon name="upload-cloud" className={styles.infoIcon} />
                  </div>
                  <div className={styles.headings}>
                    <Text variant="h3">
                      Syncing Content from {currentJob?.name} {currentJob?.type}
                    </Text>
                    <Text variant="body2">
                      We are currently syncing your Contents from{" "}
                      {currentJob?.name} {currentJob?.type} account. This
                      process ensures easy retrieval and AI-powered analysis of
                      your content.
                    </Text>
                  </div>
                </div>
                {!!currentJob?.statusInfo?.length && (
                  <div className={styles.statusInfos}>
                    {currentJob.statusInfo?.map((item) => (
                      <span>
                        <Text color="text50Color">{item.key} :</Text>
                        <Text color="greenColor">{item.value}</Text>
                      </span>
                    ))}
                  </div>
                )}
              </div>
              <Button
                variant="text"
                size="tiny"
                onClick={() => handleHideJob(currentJob?.id)}
              >
                <Icon name="cross" />
              </Button>
            </div>
          )
      )}
    </>
  )
}

export default SyncInfo
