import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { useFormik } from "formik"
import { useAlert } from "react-alert"

import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import useModal from "@components/modals/useModal"
import TextInput from "@components/ui/TextInput/TextInput"
import Button from "@components/ui/Button/Button"
import DocumentIcon from "@components/ui/Icon/DocumentIcon"
import { DataConnectorSourceName } from "@framework/types/upload"
import { IconName } from "@components/ui/Icon/IconName"
import { DocumentIconType } from "@framework/types/utils"
import Text from "@components/ui/Typography/Text"
import SelectOption from "@components/ui/Dropdown/SelectOption"
import Templates from "@components/ui/Templates"
import { useController, useStore } from "@store"
import Icon from "@components/ui/Icon/Icon"
import TooltipContainer from "@components/ui/Tooltip/TooltipContainer"
import Tooltip from "@components/ui/Tooltip/Tooltip"
import Chip from "@components/ui/Chip/Chip"
import { capitalizeFirstLetter } from "@utils/textUtils"
import Select from "@components/ui/Select/Select"

import { ModalsTypes } from "../constants"
import SharePointConfig from "./SharePointConfig"
import {
  configFormInitialValues,
  configFormValidationSchema,
  getFormHeadingsAndOtherInfo,
} from "./ConfigFormHelper"

import styles from "./ContentManagerConfigFormModal.module.sass"

export type ContentManagerConfigFormModalProps = {
  sourceName: DataConnectorSourceName
  icon?: DocumentIconType | IconName
  label: string
}

const ContentManagerConfigFormModal: React.FC<ContentManagerConfigFormModalProps> =
  observer(({ sourceName, icon, label }) => {
    const [showSecret, setShowSecret] = useState(false)
    const alert = useAlert()

    const { hideModal, hideAllModals } = useModal(
      ModalsTypes.CONTENT_MANAGER_CONFIG_FORM_MODAL
    )

    const { contentManagerController } = useController()
    const { contentManagerStore, allDatatypesStore } = useStore()
    const {
      datatypesController: { loadAllDataTypes },
    } = useController()

    const contentTypes = allDatatypesStore?.state?.data

    const { loadingCreatedContentSource, companies } = contentManagerStore || {}
    const { domainHeading, domainPlaceholder, isWebsite } =
      getFormHeadingsAndOtherInfo(sourceName)
    const formik = useFormik({
      initialValues: {
        ...configFormInitialValues,
        type: sourceName.toString(),
      },
      validationSchema: configFormValidationSchema,
      onSubmit: async (form) => {
        const success = await (isWebsite
          ? contentManagerController.connectWebsite(form)
          : contentManagerController.createContentSource(form))
        if (success) {
          alert.success(
            isWebsite
              ? "Successfully connected the website it will take sometime for the process to complete"
              : "Successfully created content source"
          )
          hideAllModals()
        } else {
          alert.error("Failed to connect")
        }
      },
    })
    useEffect(() => {
      formik.validateForm()
    }, [sourceName])

    useEffect(() => {
      loadAllDataTypes()
      contentManagerController.getCompanies()
    }, [])

    return (
      <BaseModal
        className={styles.root}
        title={
          <ModalTitle
            titleText={
              <div className={styles.titleContainer}>
                {icon && <DocumentIcon icon={icon} />}
                {capitalizeFirstLetter(label)}
              </div>
            }
          />
        }
        containerClassName={styles.container}
      >
        <Templates.RollScript
          footerSocket={
            <div className={styles.footer}>
              <Button
                variant="outlined"
                size="medium"
                onClick={hideModal}
                disabled={loadingCreatedContentSource}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                disabled={loadingCreatedContentSource}
                size="medium"
                onClick={formik.submitForm}
              >
                Connect
              </Button>
            </div>
          }
        >
          <form className={styles.form} onSubmit={formik.handleSubmit}>
            <div className={styles.inputsWrapper}>
              {isWebsite ? (
                <>
                  <div className={styles.inputContainer}>
                    <Text variant="body2">Manufacturer *</Text>
                    <Select
                      options={companies?.map((item) => ({
                        name: item.name,
                        value: item.name,
                      }))}
                      placeholder="Select Manufacturer"
                      onSelect={(option) =>
                        formik.setFieldValue(
                          "manufacturer",
                          companies.find(
                            (company) => company.name === option.value
                          )?.id
                        )
                      }
                      error={
                        !!(
                          formik.touched.manufacturer &&
                          formik.errors.manufacturer
                        )
                      }
                    />
                  </div>
                  <div className={styles.inputContainer}>
                    <Text variant="body2">Website Name *</Text>
                    <TextInput
                      name="websiteName"
                      placeholder="Website Name"
                      value={formik.values.websiteName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      withError={
                        !!(
                          formik.touched.websiteName &&
                          formik.errors.websiteName
                        )
                      }
                    />
                  </div>
                  <div className={styles.inputContainer}>
                    <Text variant="body2">{domainHeading} *</Text>
                    <TextInput
                      name="domain"
                      placeholder={domainPlaceholder}
                      value={formik.values.domain}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      withError={
                        !!(formik.touched.domain && formik.errors.domain)
                      }
                    />
                  </div>
                  <div className={styles.inputContainer}>
                    <Text variant="body2">Keywords</Text>
                    <TextInput
                      name="keywords"
                      placeholder="Enter keywords"
                      value={formik.values.keywords}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      withError={
                        !!(formik.touched.keywords && formik.errors.keywords)
                      }
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.inputContainer}>
                    <Text variant="body2">Connector Name *</Text>
                    <TextInput
                      name="name"
                      placeholder="Enter name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      withError={!!(formik.touched.name && formik.errors.name)}
                      after={
                        formik.touched.name && formik.errors.name ? (
                          <Tooltip
                            content={
                              <TooltipContainer
                                placement="left"
                                color="primary"
                              >
                                Names should be lowercase and
                                <br /> cannot contain spaces or special
                                characters
                              </TooltipContainer>
                            }
                          >
                            <Chip color="red" className={styles.error}>
                              <Icon name="info" /> Error
                            </Chip>
                          </Tooltip>
                        ) : null
                      }
                    />
                  </div>
                  <div className={styles.inputContainer}>
                    <Text variant="body2">{domainHeading} *</Text>
                    <TextInput
                      name="domain"
                      placeholder={domainPlaceholder}
                      value={formik.values.domain}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      withError={
                        !!(formik.touched.domain && formik.errors.domain)
                      }
                      after={
                        formik.touched.domain && formik.errors.domain ? (
                          <Tooltip
                            content={
                              <TooltipContainer
                                placement="left"
                                color="primary"
                              >
                                {formik.errors.domain}
                              </TooltipContainer>
                            }
                          >
                            <Chip color="red" className={styles.error}>
                              <Icon name="info" /> Error
                            </Chip>
                          </Tooltip>
                        ) : null
                      }
                    />
                  </div>
                  <div className={styles.inputContainer}>
                    <Text variant="body2">Client ID *</Text>
                    <TextInput
                      name="clientId"
                      placeholder="Enter client ID"
                      value={formik.values.clientId}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      withError={
                        !!(formik.touched.clientId && formik.errors.clientId)
                      }
                    />
                  </div>
                  <div className={styles.inputContainer}>
                    <Text variant="body2">Client Secret *</Text>
                    <TextInput
                      name="clientSecret"
                      placeholder="Enter client secret"
                      type={showSecret ? "text" : "password"}
                      value={formik.values.clientSecret}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      withError={
                        !!(
                          formik.touched.clientSecret &&
                          formik.errors.clientSecret
                        )
                      }
                      after={
                        <Icon
                          name={showSecret ? "eye-close" : "eye"}
                          className={styles.eyeIcon}
                          onClick={() => setShowSecret(!showSecret)}
                        />
                      }
                    />
                  </div>
                  <div className={styles.inputContainer}>
                    <Text variant="body2">Content Type</Text>
                    <Select
                      options={contentTypes?.map((item) => ({
                        name: item.name,
                        value: item.name,
                      }))}
                      placeholder="Select content type"
                      onSelect={(option) =>
                        formik.setFieldValue(
                          "contentType",
                          contentTypes.find(
                            (contentType) => contentType.name === option.value
                          )?.id
                        )
                      }
                    />
                  </div>

                  {sourceName === "sharepoint_online" && (
                    <SharePointConfig formik={formik} />
                  )}
                </>
              )}
            </div>
          </form>
        </Templates.RollScript>
      </BaseModal>
    )
  })

export default ContentManagerConfigFormModal
