/* eslint-disable no-debugger */
import axios, { AxiosRequestConfig, Method, AxiosResponse } from "axios"
import pickBy from "lodash/pickBy"
import identity from "lodash/identity"
import { Observable } from "rxjs"

import { fetchEventSource } from "@microsoft/fetch-event-source"
import { FetchEventSourceInit } from "@microsoft/fetch-event-source/lib/cjs/fetch"
import config from "@root/config"
import accessTokenStore from "@store/auth/access-token.store"
import { getTimeZoneString } from "@utils/date"

export type StreamEvents = Pick<
  FetchEventSourceInit,
  "onopen" | "onmessage" | "onerror" | "onclose"
>

export type HttpRequestConfig<
  Headers = any,
  Auth = any,
  Data = any,
  Params = any
> = {
  headers?: Headers
  auth?: Auth
  url: string
  method: Method
  data?: Data
  params?: Params
  responseType?: AxiosRequestConfig["responseType"]
  onUploadProgress?: AxiosRequestConfig["onUploadProgress"]
}

export type HttpConfig = {
  apiURL?: string
}

class HttpService {
  config = {
    apiURL: `${config.API_ROOT}/api`,
    headers: {
      // Accept: "application/json",
    },
  }

  constructor(params: HttpConfig = {}) {
    this.config = { ...this.config, ...pickBy(params, identity) }
  }

  getAuthHeaders = (): Record<string, string> => {
    const token = accessTokenStore.accessToken
    if (!token) return {}
    return {
      Authorization: `Bearer ${token}`,
    }
  }

  getHeaders = <Headers>(headers: Headers, withAuth: boolean) => {
    const authHeaders = !withAuth ? {} : this.getAuthHeaders()
    return { ...this.config.headers, ...headers, ...authHeaders }
  }

  getURL = (url: string) => `${this.config.apiURL}/${url}`

  request = <Headers, Auth extends boolean, Data, Params>(
    props: HttpRequestConfig<Headers, Auth, Data, Params>,
    signal?: AbortSignal,
    referrer?: string
  ) => {
    const headers: any = this.getHeaders(props.headers, !!props.auth)
    if (referrer) {
      headers["X-Referer"] = referrer
    }
    headers["X-Timezone"] = getTimeZoneString()
    const requestParams: AxiosRequestConfig = {
      url: this.getURL(props.url),
      headers,
      method: props.method,
      data: props.data,
      params: props.params,
      withCredentials: props.auth,
      responseType: props.responseType,
      onUploadProgress: props.onUploadProgress,
      signal,
    }

    return axios.request(requestParams)
  }

  requestStream = <Headers, Auth extends boolean, Data, Params>(
    props: HttpRequestConfig<Headers, Auth, Data, Params>
  ) => {
    return new Observable<AxiosResponse<any>>((observer) => {
      const controller = new AbortController()
      const headers: any = this.getHeaders(props.headers, !!props.auth)
      headers["X-Timezone"] = getTimeZoneString()

      const requestParams: AxiosRequestConfig = {
        url: this.getURL(props.url),
        headers,
        method: props.method,
        data: props.data,
        params: props.params,
        withCredentials: props.auth,
        responseType: props.responseType,
        signal: controller.signal,
        onUploadProgress: props.onUploadProgress,
      }

      axios
        .request(requestParams)
        .then((response) => observer.next(response))
        .catch((error) => observer.error(error))

      return () => controller.abort()
    })
  }

  post = <Data, Headers, Params>(
    url: string,
    data: Data,
    auth = true,
    options?: {
      headers?: Headers
      params?: Params
      onUploadProgress?: HttpRequestConfig["onUploadProgress"]
      signal?: AbortSignal
    }
  ) =>
    this.request(
      {
        method: "post",
        url,
        data,
        auth,
        headers: options?.headers,
        params: options?.params,
        onUploadProgress: options?.onUploadProgress,
      },
      options?.signal
    )

  postStream = <Data extends BodyInit | null | undefined, Headers>(
    url: string,
    data: Data,
    events: StreamEvents,
    auth = true,
    options?: {
      headers?: Headers
    }
  ) =>
    fetchEventSource(this.getURL(url), {
      openWhenHidden: true,
      method: "POST",
      headers: {
        ...this.getHeaders(
          {
            Connection: "keep-alive",
            ...options?.headers,
          },
          auth
        ),
        "X-Timezone": getTimeZoneString(),
      },
      ...events,
      body: data,
    })

  postJSONStream = <Data, Headers>(
    url: string,
    data: Data,
    events: StreamEvents,
    auth = true,
    options?: {
      headers?: Headers
    }
  ) => {
    return this.postStream(url, JSON.stringify(data), events, auth, {
      headers: {
        "Content-type": "application/json",
        "X-Timezone": getTimeZoneString(),
        ...options?.headers,
      },
    })
  }

  put = <Data, Params>(url: string, data: Data, auth = true, params?: Params) =>
    this.request({
      url,
      data,
      auth,
      params,
      method: "put",
    })

  patch = <Data, Params>(
    url: string,
    data: Data,
    auth = true,
    params?: Params
  ) =>
    this.request({
      url,
      data,
      auth,
      params,
      method: "patch",
    })

  get = <Params, Data>(
    url: string,
    auth = true,
    params?: Params,
    data?: Data,
    signal?: AbortSignal,
    referrer?: string
  ) => {
    return this.request(
      {
        url,
        params,
        auth,
        method: "get",
        data,
      },
      signal,
      referrer
    )
  }

  getStream$ = <Params, Data>(
    url: string,
    options?: Partial<{
      auth: boolean
      params: Params
      data: Data
    }>
  ) =>
    this.requestStream({
      method: "get",
      url,
      auth: true,
      ...options,
    })

  postStream$ = <Params, Data>(
    url: string,
    data: Data,
    options?: Partial<{
      auth: boolean
      params: Params
    }>
  ) =>
    this.requestStream({
      method: "post",
      url,
      auth: true,
      data,
      ...options,
    })

  download = <Params>(url: string, params?: Params, auth = true) =>
    this.request({
      url,
      params,
      auth,
      method: "get",
      responseType: "blob",
    })

  head = <Params>(url: string, auth = true, params?: Params) =>
    this.request({
      url,
      params,
      auth,
      method: "head",
    })

  delete = <Data, Params>(
    url: string,
    data?: Data,
    auth = true,
    params?: Params
  ) =>
    this.request({
      url,
      data,
      auth,
      method: "delete",
      params,
    })
}

export default HttpService
