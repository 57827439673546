import { Entity } from "./common"
import { SimpleBaseUserData } from "./user"
import { PaginationParams } from "./utils"

export const fileSourcesNames = ["manual"] as const

export const dataConnectorsNames = [
  "smb",
  "network-drive",
  "box",
  "amazon-aws-s3",
  "sharepoint",
  "sharepoint_online",
  "blobstorage",
  "microsoft-teams",
  "microsoft-one-note",
  "microsoft-one-drive",
  "slack",
  "google-drive",
  "salesforce",
  "atlassian-confluence",
  "atlassian-confluence-data-center",
  "atlassian-jira",
  "zendesk",
  "azure_blob_storage",
] as const

export const webSiteNames = ["website"] as const

export const dataSourceGroupNames = ["file", "data-connector"] as const

export const dataConnectorContentGroupNames = ["folders", "spaces"] as const

export const dataConnectorContentNames = ["file", "page"] as const

export type FileDataSourceName = (typeof fileSourcesNames)[number]

export type DataConnectorSourceName = (typeof dataConnectorsNames)[number]

export type WebsiteSourceName = (typeof webSiteNames)[number]

export type DataSourceGroupName = (typeof dataSourceGroupNames)[number]

export type DataConnectorContentGroupName =
  (typeof dataConnectorContentGroupNames)[number]

export type DataConnectorContentName =
  (typeof dataConnectorContentNames)[number]

export type RootDataSourceGroupName = WebsiteSourceName | DataSourceGroupName

export type DataSourceName =
  | FileDataSourceName
  | WebsiteSourceName
  | DataConnectorSourceName
  | DataConnectorContentGroupName
  | DataConnectorContentName

export type DataSourceNodeName = DataSourceGroupName | DataSourceName

export interface DataSourceStatus {
  name: DataSourceNodeName
  processing?: boolean
  connected?: number
  failed?: number
  loading?: number
  error?: string
  total?: number
  totalFolders?: number
  totalFiles?: number
}

export interface DataConnectorStatusReport {
  name: DataConnectorSourceName
  sourceType: "data-connector"
  totalAccounts: number
  totalFolders: number
  totalFiles: number
}

export interface FileStatusReport {
  name: FileDataSourceName
  sourceType: "file"
  count: number
}

export interface WebsiteStatusReport {
  name: WebsiteSourceName
  sourceType: "website"
  count: number
}

export type DataSourceStatusReport =
  | FileStatusReport
  | WebsiteStatusReport
  | DataConnectorStatusReport

export const isDataConnector = (
  sourceName: string
): sourceName is DataConnectorSourceName => {
  return dataConnectorsNames.includes(sourceName as DataConnectorSourceName)
}

export interface DataSourceStatusEntity extends DataSourceStatus, Entity {}

// consider to split type to data-connector, cloud-file and others
export interface DataSourceFile extends Entity {
  name: string

  status?: FileStatus

  url?: string

  createdAt?: string // Date
  lastUpdatedAt: string // Date

  completePercentage?: number // 0 - 1

  connectorType?: DataConnectorSourceName

  connectedFolders?: number

  // website attributes
  sitemapUrls?: string[]
  crawlingUrls?: string[]

  error?: string
}

export interface UploadedFile extends Entity {
  name: string

  status?: FileStatus

  url?: string

  createdAt?: string // Date
  lastUpdatedAt: string // Date

  userId?: string
  user?: SimpleBaseUserData
  updatedByUserId?: string

  knowledgedatatypes: { id: string; name: string }[]

  completePercentage?: number

  error?: string
}

export interface ConnectedWebsite extends Entity {
  name: string

  status?: FileStatus
  connectedStatus?: string

  createdAt?: string // Date
  lastUpdatedAt: string // Date

  connectorType?: DataConnectorSourceName
  connectedFolders?: number

  sitemapUrls?: string[]
  crawlingUrls?: string[]

  updatedByUserId?: string
  userId?: string

  knowledgedatatypes: { id: string; name: string }[]

  completePercentage?: number

  error?: string
}

export interface CloudFolder extends Entity {
  name: string

  status?: FolderStatus
  connectedStatus?: string

  dataConnectorId: string

  files?: string // number

  createdAt?: string // Date
  lastUpdatedAt: string // Date

  updatedByUserId?: string
  userId?: string

  knowledgedatatypes: { id: string; name: string }[]

  error?: string
}

export interface DataConnectorAccountEntity extends Entity {
  connectorType: DataConnectorSourceName
  createdAt: string
  name: string
  lastUpdatedAt: string
  totalFolders?: number
  totalFiles?: number
  userId?: string
  lastUpdatedBy?: SimpleBaseUserData
}

export type FileStatus = "deleted" | "failed" | "running" | "success"

export type FolderStatus = "loading" | "connected" | "failed"

export interface DataSourcesList<P = unknown> {
  items: P[]
  total: number
}

export interface DataSourcesListMeta extends PaginationParams {
  total: number
  totalPage: number
}

export interface ConnectingDataConnector extends DataSourceFile {
  progressPercent: number // 0 - 1
}

export interface BasicCloudFolderEntity {
  name: string
  cloudFolderId: string
}

export interface BasicCloudFolder extends Entity, BasicCloudFolderEntity {}

export type CloudEntityType = "file" | "dir"

export type CloudEntityStatus = "selected" | "excluded" | "inherit"

export interface CloudEntity extends Entity {
  cloudId: string | null
  createdAt: string
  createdBy: string | null
  dataConnectorId: string
  error?: string | null
  isUploaded: boolean
  knowledgeDataTypeId: string | null
  lastUpdatedAt: string
  name: string
  parentId: string | null
  path: string
  status: CloudEntityStatus
  type: CloudEntityType
  updatedBy: string | null
}

export interface ConnectedCloudFolder extends BasicCloudFolderEntity, Entity {
  connectedStatus: "active" | "inactive"
  files: { loading: number; connected: number }
  dataConnectorId: string
  lastUpdatedAt: string
  status: FolderStatus
  channels: unknown[]

  // no confirmed
  totalFiles?: number
  error?: string
}

export interface DataConnectorFile extends DataSourceFile {
  uploadType: DataConnectorSourceName // not confirmed
  url: string
  start_time: string // Date
  end_time: string // Date
  file_size_in_mb: number
}
