import { observer } from "mobx-react-lite"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAlert } from "react-alert"

import MainLayout from "@components/layout/MainLayout/MainLayout"
import Container from "@components/ui/Container/Container"
import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import EntityPageHeaderLayout from "@components/layout/EntityPageHeaderLayout/EntityPageHeaderLayout"
import BackButton from "@components/prototypes/BackButton"
import Button from "@components/ui/Button/Button"
import TextInput from "@components/ui/TextInput/TextInput"
import Icon from "@components/ui/Icon/Icon"
import Pagination from "@components/ui/Pagination/Pagination"
import usePagination from "@components/ui/Pagination/usePagination"
import { useController, useStore } from "@store"
import LoadingCover from "@components/ui/Loader/LoadingCover"
import NotFound from "@components/ui/NotFound/NotFound"
import Text from "@components/ui/Typography/Text"
import Skeleton from "@components/ui/Skeleton/Skeleton"
import { ConnectorDocument } from "@framework/types/content-manager"

import ContentRow from "./components/ContentRow/ContentRow"
import SyncInfo from "./components/SyncInfo/SyncInfo"
import SearchInfoBanner from "./components/SearchInfoBanner/SearchInfoBanner"

import styles from "./ContentManager.module.sass"

export const ContentManagerPage: React.FC = observer(() => {
  const navigate = useNavigate()
  const alert = useAlert()
  const [selectedRows, setSelectedRows] = useState<ConnectorDocument[]>([])
  const filtersModal = useModal(ModalsTypes.CONTENT_MANAGER_FILTERS_MODAL)
  const contentTypeModal = useModal(ModalsTypes.SELECT_CONTENT_TYPE_MODAL)
  const confirmModal = useModal(ModalsTypes.CONFIRM_MODAL)

  const { contentManagerController } = useController()
  const { contentManagerStore } = useStore()
  const {
    documentsList,
    loadingDocumentsList,
    documentsMeta,
    filters,
    loadingSyncJobs,
    getAvailableFilterOptions,
  } = contentManagerStore

  const paginationProps = usePagination({
    pageSize: documentsMeta?.pageSize || 0,
    totalRows: documentsMeta?.total || 0,
    totalPages: documentsMeta?.totalPages || 0,
  })
  const chooseContentSourceModal = useModal(
    ModalsTypes.CHOOSE_CONTENT_SOURCE_MODAL
  )

  const handleConnect = () => {
    chooseContentSourceModal.showModal({
      doRefresh: () => {
        if (paginationProps?.onPageChange) {
          paginationProps.onPageChange({ selected: 1 })
        }
      },
    })
  }

  const onDelete = async (docs: ConnectorDocument[]): Promise<void> => {
    const error = await contentManagerController.deleteConnectorDocuments(docs)
    if (error) {
      alert.error(error)
    } else {
      alert.info("Successfully deleted")
      setSelectedRows([])
      setTimeout(loadPage, 1000) // added timeout as it takes sometime to deleted documents refreshed
    }
  }

  const showFilters = (event: React.MouseEvent<HTMLButtonElement>) => {
    const buttonElement = event.currentTarget
    const rect = buttonElement.getBoundingClientRect()
    const top = rect.top + 48 // 48px is the height + gap for filter button
    const left = rect.left + rect.width
    filtersModal.showModal({
      top,
      left,
      filtersList: getAvailableFilterOptions(),
    })
  }

  const loadPage = () =>
    contentManagerController.loadDocumentsList({
      pageSize: 10,
      pageNum: paginationProps.forcePage,
    })

  useEffect(() => {
    loadPage()
  }, [paginationProps.forcePage])

  useEffect(() => {
    contentManagerController.loadContentTypes()
    contentManagerController.loadContentSources()
    contentManagerController.loadSyncJobs()
  }, [])

  const clearSelection = () => setSelectedRows([])

  const showContentTypeModal = (doc?: ConnectorDocument) => {
    contentTypeModal.showModal({
      documents: doc ? [doc] : selectedRows,
      onAssign: () => {
        setSelectedRows([])
        alert.success("Successfully assigned content type")
        loadPage()
      },
    })
  }

  const showDeleteConfirmModal = (doc?: ConnectorDocument) => {
    const docs = doc ? [doc] : selectedRows
    confirmModal.showModal({
      message: (
        <>
          Delete Document{docs.length > 1 && `'s`}
          <br />
          Are you sure you want to proceed?
        </>
      ),
      confirm: "positive",
      onConfirm: () => onDelete(docs),
      confirmButtonText: "Proceed",
    })
  }

  return (
    <MainLayout noPadding>
      <Container>
        <div className={styles.root}>
          <EntityPageHeaderLayout
            className={styles.header}
            left={
              <BackButton size="small" onClick={() => navigate("../")}>
                Content Manager
              </BackButton>
            }
            right={
              <Button color="primary" size="big" onClick={handleConnect}>
                Add Content
              </Button>
            }
          >
            <TextInput
              before={<Icon name="search" />}
              placeholder="Search"
              onChange={() => {}}
            />
          </EntityPageHeaderLayout>
          <SearchInfoBanner />
          {loadingSyncJobs && <Skeleton className={styles.syncSkeleton} />}
          {!!contentManagerStore?.syncJobs?.length && (
            <SyncInfo jobs={contentManagerStore.syncJobs} />
          )}
          <div className={styles.filterContainer}>
            <div>
              {!!selectedRows.length && (
                <Button
                  size="medium"
                  before={<Icon onClick={clearSelection} name="cross" />}
                  color="secondary"
                  className={styles.deleteChip}
                  onClick={contentManagerStore.clearFilters}
                >
                  {selectedRows.length} Selected
                  <div className={styles.selectedFilterDelete}>
                    <Icon
                      onClick={() => showContentTypeModal()}
                      name="storage"
                    />
                    <Icon
                      onClick={() => showDeleteConfirmModal()}
                      name="trash-can"
                      color="red"
                    />
                  </div>
                </Button>
              )}
            </div>
            {/* <Button
              color="secondary"
              size="medium"
              before={<Icon name="funnel" />}
              onClick={(e) => showFilters(e)}
            >
              {!isMobile && "Filters"}
            </Button> */}
          </div>
          <LoadingCover
            isLoading={loadingDocumentsList}
            className={styles.body}
          >
            {!loadingDocumentsList && !documentsList?.length && (
              <NotFound className={styles.notFound}>
                <Text variant="h2">No documents found</Text>
              </NotFound>
            )}
            {documentsList?.map((item) => (
              <ContentRow
                selected={selectedRows.map((it) => it.id).includes(item.id)}
                onSelect={(val) => {
                  setSelectedRows((prevSelectedRows) =>
                    prevSelectedRows.map((it) => it.id).includes(val.id)
                      ? prevSelectedRows.filter((row) => row.id !== val.id)
                      : [...prevSelectedRows, val]
                  )
                }}
                key={item.id}
                data={item}
                onDelete={(doc) => showDeleteConfirmModal(doc)}
                showContentTypeModal={() => showContentTypeModal(item)}
              />
            ))}
          </LoadingCover>
          {!!documentsList?.length && (
            <EntityPageHeaderLayout
              className={styles.footer}
              right={<Pagination {...paginationProps} />}
            />
          )}
        </div>
      </Container>
    </MainLayout>
  )
})

export default ContentManagerPage
