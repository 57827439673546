import { AxiosProgressEvent, AxiosResponse } from "axios"

import {
  ConnectWebsiteParams,
  CreateContentSourceParams,
  CreateContentSourceResponse,
  DocumentParams,
  DocumentsListResponse,
  GetConnectorDocumentsParams,
  GetKibanaConnectorAccountsResponse,
  GetSyncJobsResponse,
  KibanaConnectorInfo,
  UpdateDocumentContentTypeParams,
  WebsiteInfo,
} from "@framework/types/content-manager"
import { Company } from "@framework/types/company"

import HttpService from "./http.service"

class ContentManagerAPI extends HttpService {
  getAvailableContentSources = (
    type: string
  ): Promise<AxiosResponse<GetKibanaConnectorAccountsResponse>> =>
    this.get("ts/kibana-connectors", true, {
      type,
      pageSize: 1000,
      pageNum: 1,
    })

  getKibanaConnectorsCount = (): Promise<
    AxiosResponse<KibanaConnectorInfo[]>
  > => this.get(`ts/kibana-connectors/counts`, true)

  createContentSource = (
    data: CreateContentSourceParams
  ): Promise<AxiosResponse<CreateContentSourceResponse>> =>
    this.post("ts/kibana-connectors", data, true)

  deleteKibanaConnectorAccount = (id: string): Promise<AxiosResponse<any>> =>
    this.delete(`ts/kibana-connectors/${id}`, true)

  connectWebsite = (
    data: ConnectWebsiteParams
  ): Promise<AxiosResponse<CreateContentSourceResponse>> =>
    this.post("ts/kibana-connectors/website/crawls", data, true)

  getWebsites = (isRunning?: boolean): Promise<AxiosResponse<WebsiteInfo[]>> =>
    this.get(
      "ts/kibana-connectors/website/crawls",
      true,
      isRunning ? { isRunning: true } : {}
    )

  getDocumentsList = (
    params: GetConnectorDocumentsParams
  ): Promise<AxiosResponse<DocumentsListResponse>> =>
    this.get("ts/kibana-connectors/documents", true, params)

  getSyncJobs = (): Promise<AxiosResponse<GetSyncJobsResponse>> =>
    this.get("ts/kibana-connectors/sync-jobs", true, {
      pageSize: 10,
      pageNum: 1,
      status: "in_progress",
    })

  uploadDocument = (
    file: File,
    contentType?: string,
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
  ): Promise<AxiosResponse<any>> => {
    const formData = new FormData()
    formData.append("file", file)
    if (contentType) formData.append("contentType", contentType)

    return this.post("ts/kibana-connectors/documents", formData, true, {
      headers: {
        "Content-Type": "application/form-data",
      },
      onUploadProgress,
    })
  }

  updateContentTypeForDocument = (
    params: UpdateDocumentContentTypeParams
  ): Promise<AxiosResponse<DocumentsListResponse>> =>
    this.patch(
      `ts/kibana-connectors/${params.connectorId}/documents/${params.documentId}`,
      {
        contentType: params.contentType,
      },
      true
    )

  getCompanies = (): Promise<AxiosResponse<Company[]>> =>
    this.get(`ts/v2/companies`, true)

  deleteDocument = (
    params: DocumentParams
  ): Promise<AxiosResponse<DocumentsListResponse>> =>
    this.delete(
      `ts/kibana-connectors/${params.connectorId}/documents/${params.documentId}`,
      true
    )
}

export {}

export default new ContentManagerAPI()
