import React from "react"

import Text from "@components/ui/Typography/Text"
import {
  QueryFilterCategory,
  ProductFilterKeys,
  QueryFilterData,
  CategoryInfo,
} from "@framework/types/query-filter"

import { FilterButton } from "./FilterButton"

import styles from "./ProductsSection.module.sass"

interface FilterListProps {
  availableFilters: {
    product: Record<string, QueryFilterCategory>
    data: Record<string, QueryFilterCategory>
  } | null
  setSelectedCategory: React.Dispatch<
    React.SetStateAction<CategoryInfo | undefined>
  >
  handleSubcategorySelect: (filter: QueryFilterData) => void
}

const FilterList: React.FC<FilterListProps> = ({
  availableFilters,
  setSelectedCategory,
  handleSubcategorySelect,
}) => {
  const hasContentToShow =
    availableFilters?.data &&
    Object.values(availableFilters.data).some(
      (value) => (value?.totalCount || 0) > 0
    )

  return (
    <>
      {!!availableFilters?.product?.products?.count && (
        <>
          <Text variant="body2" className={styles.categoryHeader}>
            Product
          </Text>
          <div className={styles.filterTypes}>
            <FilterButton
              category="product"
              name="products"
              count={availableFilters?.product?.products?.totalCount}
              queryFilterCategory={availableFilters?.product?.products}
              setSelectedCategory={setSelectedCategory}
            />
            {availableFilters?.product?.[
              "categories" as ProductFilterKeys
            ]?.items?.map((item) => (
              <FilterButton
                key={item.name}
                category="product"
                name={item.name}
                count={item.count}
                queryFilterCategory={item as QueryFilterCategory}
                setSelectedCategory={() => handleSubcategorySelect(item)}
              />
            ))}
          </div>
        </>
      )}
      {hasContentToShow && (
        <>
          <Text variant="body2" className={styles.categoryHeader}>
            Content
          </Text>
          <div className={styles.filterTypes}>
            {Object.entries(availableFilters.data).map(([key, value]) =>
              (value?.totalCount || 0) > 0 ? (
                <FilterButton
                  key={key}
                  category="data"
                  count={value?.totalCount}
                  name={key as ProductFilterKeys}
                  queryFilterCategory={value}
                  setSelectedCategory={setSelectedCategory}
                />
              ) : null
            )}
          </div>
        </>
      )}
    </>
  )
}

export default FilterList
